import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import {
  Box,
  Button, Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  StepContent,
  Typography,
} from '@mui/material';
import {Compiler} from 'react-three-mind';
import Resizer from 'react-image-file-resizer';
import { useDropzone } from 'react-dropzone';
import CircularProgress from '@mui/material/CircularProgress';
import ImageDropZone from './ImageDropZone';
import useCreateStore from '../store/useCreateStore';

const fileTypes = ['JPG', 'PNG'];
const compiler = new Compiler();

function CreateImageStep(props) {
  const { handleNext, handleBack } = props;
  const { setImageStore, setMarkerStore } = useCreateStore();

  const [preview, setPreivew] = useState(null);
  const [tracking, setTracking] = useState(null);
  const [compiling, setCompiling] = useState(false);
  const [compiled, setCompiled] = useState(false);
  const [progress, setProgress] = useState(0);

  const [trackingCount, setTrackingCount] = useState(0);
  const [openImageConfirm, setImageConfirm] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path}
      {' '}
      -
      {file.size}
      {' '}
      bytes
    </li>
  ));

  const showImage = (targetImage, points) => {
    // const container = document.getElementById('container');
    const canvas = document.createElement('canvas');
    // container.appendChild(canvas);
    canvas.width = targetImage.width;
    canvas.height = targetImage.height;
    canvas.style.width = canvas.width;
    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = new Uint32Array(imageData.data.buffer);

    // eslint-disable-next-line no-bitwise
    const alpha = (0xff << 24);
    for (let c = 0; c < targetImage.width; c += 1) {
      for (let r = 0; r < targetImage.height; r += 1) {
        const pix = targetImage.data[r * targetImage.width + c];
        // eslint-disable-next-line no-bitwise
        data[r * canvas.width + c] = alpha | (pix << 16) | (pix << 8) | pix;
      }
    }

    // eslint-disable-next-line no-bitwise
    const pix = (0xff << 24) | (0x00 << 16) | (0xff << 8) | 0x00; // green
    for (let i = 0; i < points.length; i += 1) {
      const { x } = points[i];
      const { y } = points[i];
      const offset = (x + y * canvas.width);
      data[offset] = pix;
      // for (var size = 1; size <= 3; size++) {
      for (let size = 1; size <= 6; size += 1) {
        data[offset - size] = pix;
        data[offset + size] = pix;
        data[offset - size * canvas.width] = pix;
        data[offset + size * canvas.width] = pix;
      }
    }
    ctx.putImageData(imageData, 0, 0);

    setTracking(canvas.toDataURL());
  };

  const resizeImage = (file) => new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      'JPEG',
      80,
      0,
      (uri) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = URL.createObjectURL(uri);

        setPreivew(img.src);
        setImageStore(uri);
      },
      'file',
      512,
      512,
    );
  });

  const compile = async (file) => {
    setCompiled(false);
    setCompiling(true);

    const images = [];
    images.push(await resizeImage(file));
    const dataList = await compiler.compileImageTargets(images, (p) => {
      setProgress(Math.round(p));
    });
    console.log(dataList);
    // for (let i = 0; i < dataList.length; i += 1) {
    //   showData(dataList[i]);
    // }

    const image = dataList[0].trackingImageList[0];
    const points = dataList[0].trackingData[0].points.map(
      (p) => ({ x: Math.round(p.x), y: Math.round(p.y) }),
    );
    setTrackingCount(points.length);
    showImage(image, points);

    const exportedBuffer = await compiler.exportData();
    setMarkerStore(exportedBuffer);

    setCompiling(false);
    setCompiled(true);
  };

  const handleDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
    setProgress(0);
    setTracking(null);
    setTrackingCount(0);
    compile(acceptedFiles[0]);
  };

  const confirmImage = () => {
    if (trackingCount > 10) {
      handleNext();
    } else {
      setImageConfirm(true);
    }
  };

  const handleImageConfirm = () => {
    handleNext();
    setImageConfirm(false);
  };

  const handleImageClose = () => {
    setImageConfirm(false);
  };

  return (
    <StepContent>
      <Typography variant={'subtitle2'} >마커로 인식할 이미지를 등록해주세요.</Typography>

      <ImageDropZone handleDrop={handleDrop} />

      <Divider variant="middle" className="!mt-4 !mb-4" />

      { (compiled && !compiling)
        && (
        <Grid container>
          <Grid item xs={12} md={6} className="p-1">
            <Paper variant="outlined" className="min-h-full">
              {!preview && (
              <div className="relative mx-auto w-16">
                <Typography
                  variant="overline"
                  display="block"
                >
                  PREVIEW
                </Typography>
              </div>
              )}
              {!preview && <img src="/assets/images/blank.png" />}
              {preview && <img src={preview} />}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} className="p-1">
            <Paper variant="outlined" className="min-h-full">
              {!tracking && (
              <div className="relative mx-auto w-16">
                <Typography
                  variant="overline"
                  display="block"
                >
                  TRACKING
                </Typography>
              </div>
              )}
              {!tracking && <img src="/assets/images/blank.png" />}
              {tracking && <img src={tracking} />}
            </Paper>
          </Grid>
        </Grid>
        )}

      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>

      <Box className="mt-4 mb-4">
        {(trackingCount > 35) && <Typography variant="body1">인식률이 좋네요. 이제 타입을 골라 볼까요?</Typography>}
        {(trackingCount > 10 && trackingCount < 36)
          && <Typography variant="body1">인식률이 보통입니다. 멋진 타입을 골라보세요.</Typography>}
        {(trackingCount > 0 && trackingCount < 11)
          && <Typography variant="body1">인식률이 좋지 않네요. 좀 더 복잡한 사진은 어떨까요?</Typography>}
        {(trackingCount === 0 && !compiling)
          && <Typography variant="body1">온데이에 사용 할 이미지를 업로드해 주세요.</Typography>}
        {compiling && <Typography variant="body1">이미지를 분석 중 입니다.</Typography>}
      </Box>

      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            disabled={!compiled}
            variant="contained"
            onClick={confirmImage}
            sx={{ mt: 1, mr: 1 }}
          >
            다음
          </Button>
          <Button
            onClick={handleBack}
            sx={{ mt: 1, mr: 1 }}
          >
            이전
          </Button>
        </div>
      </Box>

      <Dialog
        open={openImageConfirm}
        onClose={handleImageClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          인실률 안내
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            인식률이 나쁜 경우 온데이가 정상 작동하지 않을 수 있습니다. 그대로 진행할까요?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImageClose}>
            이미지 다시 선택
          </Button>
          <Button onClick={handleImageConfirm} autoFocus>
            다음
          </Button>
        </DialogActions>
      </Dialog>

      {compiling
        && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 bg-opacity-50 bg-black z-10 text-center items-center"
        >
          <CircularProgress className="absolute top-1/2" />
        </div>
        )}

    </StepContent>
  );
}

export default CreateImageStep;
