import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {doc, getDoc} from 'firebase/firestore';
import About from './components/About';
import OnDay from './components/OnDay';
import useDataStore from './store/useDataStore';
import json from './assets/data.json';
import {db} from './FirebaseApp';
import Create from './components/Create';
import Loading from "./components/Loading";

function App() {
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const { data, setData, setAllData } = useDataStore((state) => state);

  const formatDate = (d) => `${d.getFullYear()}.${(`0${d.getMonth() + 1}`).slice(-2)}.${(`0${d.getDate()}`).slice(-2)
  } ${(`0${d.getHours()}`).slice(-2)}:${(`0${d.getMinutes()}`).slice(-2)}:${(`0${d.getSeconds()}`).slice(-2)}`;

  const fetchData = async () => {
    const mid = location.pathname.split('/')[1];
    if(mid){
      if (json.hasOwnProperty(mid)) {
        setData(json[mid]);
      } else {
        const docRef = doc(db, 'markers', mid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          data.create = formatDate(data.create.toDate());
          data.update = formatDate(data.update.toDate());
          data.onday = formatDate(data.onday.toDate());
          setData(data);
        }
      }
      setAllData(json);
    }
    setLoaded(true);
  };

  useEffect(() => {
    fetchData();
  }, [location]);

  return (
    <Routes>
      {!loaded && <Route path="*" element={<Loading />} />}
      {loaded && !data && <Route path="*" element={<About />} />}
      {loaded &&  data && <Route path="*" element={<OnDay />} />}
      <Route path="create" element={<Create />} />
      {/* {data && <Route path={"*"} element={<ModuleViewer/>}/>} */}
    </Routes>
  );
}

export default App;
