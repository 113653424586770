// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAwBdfSws3a0_PJ6x1O4u1zeuLHwWJAcco',
  authDomain: 'onday-bfbf8.firebaseapp.com',
  projectId: 'onday-bfbf8',
  storageBucket: 'onday-bfbf8.appspot.com',
  messagingSenderId: '1012289877005',
  appId: '1:1012289877005:web:63b0495c7466440b95d144',
  measurementId: 'G-XYVR6SVHBB',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth();
const functions = getFunctions(firebaseApp);
const analytics = getAnalytics(firebaseApp);
const storage = getStorage();

export {
  firebaseApp, db, auth, functions, analytics, storage,
};
