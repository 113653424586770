import Lottie from 'react-lottie';
import * as animationData from '../animations/loading.json'
import Logo from "./Logo";
import onday from "../assets/images/onday.svg";

const Loading = ()=>{
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return(
    <div className={"w-screen h-screen opacity-100"}>
      <div className={"absolute w-full top-20"}>
        <div className={"w-fit mx-auto"}>
          <img src={onday}  alt="onday" className={"mx-auto h-20 "} />
        </div>
      </div>
      <div className={"flex justify-center items-center h-screen"}>
        <div className={"grid-rows-2"}>
          <div>
            <Lottie options={defaultOptions}
                    height={80}
                    width={80}/>
          </div>
          <div className={"text-white text-center"}>LOADING ...</div>
        </div>
      </div>
      <Logo/>
    </div>
  )
}

export default Loading