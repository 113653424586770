import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  StepContent,
  TextField,
  Typography,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import { MuiTelInput } from 'mui-tel-input';
import useCreateStore from '../store/useCreateStore';

function CreateDateStep(props) {
  const { handleNext, handleBack, handleOpen } = props;
  const {
    setTitleStore, setOndayStore, setStartdayStore, fetch, setCreated, setMobileStore,
  } = useCreateStore();

  const now = dayjs().second(0).valueOf();

  const [onday, setOnday] = useState(now);
  const [title, setTitle] = useState('');
  const [mobile, setMobile] = useState('');
  const [startday, setStartday] = useState(false);
  const [titleErr, setTitleErr] = useState(false);
  const [uploading, setUploading] = useState(false);

  const handleTitle = (e) => {
    setTitle(e.target.value);
    setTitleStore(e.target.value);
    setTitleErr(false)
  };

  const handleMobile = (e) => {
    setMobile(e);
    setMobileStore(e);
    setTitleErr(false)
  };

  const handleDate = (e) => {
    console.log(e.unix());
    setOnday(e);
    setOndayStore(e.unix());
  };

  const handleStartday = (e) => {
    setStartday(e.target.checked);
    setStartdayStore(e.target.checked);
  };

  const handleConfirm = () => {
    if (title) {
      setTitleErr(false);
      setUploading(true);
      fetch().then(() => {
        console.log('%c Complete fetch', 'color: red');
        setCreated(true);
        setUploading(false);
      });
    } else setTitleErr(true);
  };

  useEffect(() => {
    setTitleStore('');
    setOndayStore(parseInt(onday / 1000));
    setStartdayStore(false);
  }, []);

  return (
    <StepContent>
      <Grid container>
        <Grid item xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={4}>
              <Typography variant={'subtitle2'} >콘텐츠와 함께 보여질 메시지를 설정해 주세요.</Typography>
              <TextField
                id="title"
                label="제목"
                variant="outlined"
                onChange={handleTitle}
                required
                error={titleErr}
                helperText={titleErr && '제목은 필수 항목 입니다.' }
              />
              <MobileDatePicker
                label="카운트 시작 날짜"
                inputFormat="YYYY-MM-DD"
                value={onday}
                onChange={handleDate}
                renderInput={(params) => <TextField {...params} />}
              />
              <TimePicker
                label="카운트 시작 시각"
                value={onday}
                onChange={handleDate}
                renderInput={(params) => <TextField {...params} />}
              />
              <Box>
                <FormControlLabel control={<Checkbox onChange={handleStartday} />} label="(선택) 설정일로부터 1일" />
                <Typography variant="caption">디데이 날짜를 1일로 포함합니다.</Typography>
              </Box>
            </Stack>

            {/*<Grid container>*/}
            {/*  <Grid item md={6} xs={12}>*/}
            {/*    <Box>*/}
            {/*      <FormControlLabel control={<Checkbox />} label="(선택) 링크 문자 전송 동의" />*/}
            {/*      <Button onClick={handleOpen}>개인정보처리 약관</Button>*/}
            {/*    </Box>*/}
            {/*  </Grid>*/}
            {/*  <Grid item md={6} xs={12} textAlign="center">*/}
            {/*    <MuiTelInput*/}
            {/*      value={mobile}*/}
            {/*      onChange={handleMobile}*/}
            {/*      defaultCountry="KR"*/}
            {/*      className="w-full"*/}
            {/*    />*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}

          </LocalizationProvider>

        </Grid>
      </Grid>

      <Divider variant="middle" className="!mt-4 !mb-4" />

      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            onClick={handleConfirm}
            sx={{ mt: 1, mr: 1 }}
          >
            완료
          </Button>
          <Button
            onClick={handleBack}
            sx={{ mt: 1, mr: 1 }}
          >
            이전
          </Button>
        </div>
      </Box>

      {uploading
          && (
          <div
            className="fixed top-0 left-0 right-0 bottom-0 bg-opacity-50 bg-black z-10 text-center items-center"
          >
            <CircularProgress className="absolute top-1/2" />
          </div>
          )}
    </StepContent>

  );
}

export default CreateDateStep;
