/* eslint-disable */

import Logo from './Logo';
import onday from '../assets/images/onday.svg';
import {
  Box, Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Divider,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import logo from "../assets/images/logo.svg";
import React, {useEffect} from "react";



import EventBus from "../fluid/utils/EventBus";
window.EventBus = EventBus;
import WebGL from "../fluid/modules/WebGL";





function About() {

  let webglMng

  useEffect(() => {

    if(document.getElementById("fluid")===null){

      console.log("create fluid");

      const wrapper = document.createElement('div');
      wrapper.setAttribute("id", "fluid");
      webglMng = new WebGL({
        $wrapper: wrapper
      });
      document.body.appendChild(wrapper);

      console.log(webglMng);
      console.log(wrapper);
    }

  }, [])


  return (
    <Container maxWidth="lg" style={{minWidth: "300px"}}>
      {/* ---------------------- 시작 ----------------------*/}
      <Grid container spacing={2} className={"min-h-screen"}>
        <Grid item xs={12} className={"flex items-center content-center"}>
          <img src={onday} alt="onday" className="mx-auto h-20 "/>
        </Grid>

        <Grid item sm={3} xs={12}/>
        <Grid item sm={6} xs={12}>
          <br/>
          <Typography variant={"h4"} color={"white"} className={"text-center"}>
            기념일을 즐기는 새로운 방법, 온데이
          </Typography>
          <br/>
          <Typography variant={"h6"} color={"white"} className={"text-center"}>
            이제 막 연인이 된 날,<br/>
            사랑하는 부모님의 생신,<br/>
            너무나 기다려지는 결혼식,<br/>
            남자친구의 전역일,<br/>
            우리 아기와의 첫 만남,<br/>
            <br/>
          </Typography>
          <Typography variant={"body2"} color={"white"} className={"text-center"}>
            온데이는 이런 소중한 순간을 기억하고자 하는 디데이 카운터입니다.<br/>
          </Typography>
          <br/><br/>
          <Button
            variant="contained"
            color="third"
            size={"large"}
            fullWidth={true}
            className={"mx-auto my-auto"}
            onClick={() => {
              location.href = "/create"
            }}
          >
            온데이 시작하기
          </Button>
        </Grid>
        <Grid item sm={3} xs={12}/>
      </Grid>


      {/* ---------------------- 유튜브 ----------------------*/}
      <Grid container spacing={2} className={"min-h-screen"}>
        <Grid item xs={12}>
          <Divider className={"py-12"}/>
        </Grid>
        <Grid item xs={12}>
          <div style={{
            width: "100%",
            paddingTop: "56.25%",
            overflow: "hidden",
            position: "relative",
            borderRadius: "5px"
          }}>
            <div style={{width: "100%", height: "100%", top: 0, left: 0, position: "absolute"}}>
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/2vtWnePc_vQ?controls=0"
                      title="YouTube video player" frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowFullScreen></iframe>
            </div>
          </div>
        </Grid>

        <Grid item sm={4} xs={12}/>
        <Grid item sm={4} xs={12}>
          <br/>
          <Typography variant={"h5"} color={"white"} className={"text-center"}>더 많은 영상을 감상하세요.</Typography>
          <br/>
          <Button
            variant="contained"
            color="third"
            size={"large"}
            fullWidth={true}
            className={"mx-auto my-auto"}
            onClick={() => {
              window.open("https://www.youtube.com/watch?v=uOd1GAKjhB4&list=PLho88fqVeDQYufRsU2leLJjKbNO_UohZK", "_blank");
            }}
          >
            유튜브 바로가기
          </Button>
        </Grid>
        <Grid item sm={4} xs={12}/>
      </Grid>


      {/* ---------------------- 콘텐츠 ----------------------*/}
      <Grid container spacing={2} className={"min-h-screen"}>
        <Grid item xs={12}>
          <Divider className={"py-12"}/>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h5"} color={"white"}>3가지의 특별한 콘텐츠</Typography>
          <Typography variant={"body"} color={"whitesmoke"}>기념일에 맞는 콘텐츠를 선택해보세요.</Typography>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Card sx={{maxWidth: 345, minHeight: 601}}>
            <CardMedia
              component="img"
              height="140"
              image="/assets/images/type_birthday.png"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                생일
              </Typography>
              <Typography variant="body2" color="text.secondary">
                1년에 한번. 친구, 조카, 부모님, 연인 등 생일을 축하해주세요.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Card sx={{maxWidth: 345, minHeight: 601}}>
            <CardMedia
              component="img"
              height="140"
              image="/assets/images/type_wedding.png"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                웨딩
              </Typography>
              <Typography variant="body2" color="text.secondary">
                인생에 가장 아름답게 빛나는 순간을 기념해주세요.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item sm={4} xs={12}>
          <Card sx={{maxWidth: 345, minHeight: 601}}>
            <CardMedia
              component="img"
              height="140"
              image="/assets/images/type_couple.png"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                커플
              </Typography>
              <Typography variant="body2" color="text.secondary">
                우리가 시작한지 얼마나 됐을까요? 기념일을 기록해보세요.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>


      {/* ---------------------- 튜토리얼 ----------------------*/}
      <Grid container spacing={2} className={"min-h-screen"}>
        <Grid item xs={12}>
          <Divider className={"py-12"}/>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"h5"} color={"white"}>쉽고 빠른 체험</Typography>
          <Typography variant={"body"} color={"whitesmoke"}>간단하게 선택하면 바로 체험 할 수 있어요.</Typography>
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <Card sx={{maxWidth: 345, minHeight: 436}}>
            <CardMedia
              component="img"
              height="140"
              image="/assets/images/step1.png"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                1. 마커 타입 선택
              </Typography>
              <Typography variant="body2" color="text.secondary">
                마커는 이미지와 얼굴 두 가지 타입이 있습니다. 이미지는 언제나 같은 사진을 사용하고, 얼굴은 누구나 쉽게 만들 수 있습니다.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Card sx={{maxWidth: 345, minHeight: 436}}>
            <CardMedia
              component="img"
              height="140"
              image="/assets/images/step2.png"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                2. 이미지 선택
              </Typography>
              <Typography variant="body2" color="text.secondary">
                마커를 이미지로 선택했다면 인식을 원하는 이미지를 업로드 해주세요.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Card sx={{maxWidth: 345, minHeight: 436}}>
            <CardMedia
              component="img"
              height="140"
              image="/assets/images/step3.png"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                3. 콘텐츠 선택
              </Typography>
              <Typography variant="body2" color="text.secondary">
                생일, 웨딩, 커플 중 원하는 콘텐츠를 선택해주세요. 더 재미난 콘텐츠가 많이 추가될 예정입니다.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Card sx={{maxWidth: 345, minHeight: 436}}>
            <CardMedia
              component="img"
              height="140"
              image="/assets/images/step4.png"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                4. 날짜 입력
              </Typography>
              <Typography variant="body2" color="text.secondary">
                기념하고 싶은 날짜를 등록해주세요. 온데이가 알아서 기념일을 계산해 드립니다.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>


      {/* ---------------------- 튜토리얼 ----------------------*/}
      <Grid container spacing={2} className={"min-h-screen"}>
        <Grid item xs={12}>
          <Divider className={"py-12"}/>
        </Grid>

        <Grid item sm={4} xs={12}/>
        <Grid item sm={4} xs={12}>
          <br/>
          <Typography variant={"h5"} color={"white"} className={"text-center"}>지금 바로 무료로 시작해보세요.</Typography>
          <br/>
          <Button
            variant="contained"
            color="third"
            size={"large"}
            fullWidth={true}
            className={"mx-auto my-auto"}
            onClick={() => {
              location.href = "/create"
            }}
          >
            온데이 시작하기
          </Button>
        </Grid>
        <Grid item sm={4} xs={12}/>

        <Grid item xs={12}>
          <Divider className={"py-8"}/>
        </Grid>

        <Grid item xs={12}>
          <Box className={"pt-24 pb-24"}>
            <a href={"https://bien.ltd"} target={"_blank"} rel="noreferrer">
              <img src={logo} alt="logo" className={"mx-auto my-auto h-10 "}/>
            </a>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default About;
