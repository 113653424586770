import React, { useState } from 'react';
import {
  Box,
  Container, Grid, Paper, Step, StepLabel, Stepper, Typography,
} from '@mui/material';
import QRCode from 'react-qr-code';
import logo from '../assets/images/onday.svg';
import CreateDateStep from './CreateDateStep';
import CreateContentStep from './CreateContentStep';
import CreateImageStep from './CreateImageStep';
import bienlogo from '../assets/images/logo.svg';
import useCreateStore from '../store/useCreateStore';
import CreateControllerStep from './CreateControllerStep';
import ControllerType from '../store/ControllerType';
import Privacy from "./Privacy";

function Create() {
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const { id, created, controller } = useCreateStore();

  const host = `${window.location.protocol}//${window.location.host}`;

  const handleNext = () => {
    if (controller === ControllerType.FACE && activeStep === 0) {
      setActiveStep(2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (controller === ControllerType.FACE && activeStep === 2) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  
  const handleOpen =  () => setOpen(true);
  
  const handleClose =  () => setOpen(false);

  return (
    <Container maxWidth="md" className="bg-indigo-500">
      <Grid container>
        <Grid item xs={12}>

          <div className="mb-4">
            <img src={logo} alt="onday" className="mx-auto h-20 " />
          </div>

          <Privacy open={open} handleClose={handleClose} />

          {!created
            && (
              <Paper className="p-2">
                <Stepper activeStep={activeStep} orientation="vertical">
                  <Step key={0}>
                    <StepLabel>
                      마커타입 선택
                    </StepLabel>
                    <CreateControllerStep
                      handleNext={handleNext}
                      handleBack={handleBack}
                    />
                  </Step>

                  <Step key={1}>
                    <StepLabel>
                      이미지 선택
                    </StepLabel>
                    <CreateImageStep
                      handleNext={handleNext}
                      handleBack={handleBack}
                    />
                  </Step>

                  <Step key={2}>
                    <StepLabel>
                      콘텐츠 선택
                    </StepLabel>
                    <CreateContentStep
                      handleNext={handleNext}
                      handleBack={handleBack}
                    />
                  </Step>

                  <Step key={3}>
                    <StepLabel>
                      날짜 입력
                    </StepLabel>
                    <CreateDateStep
                      handleNext={handleNext}
                      handleBack={handleBack}
                      handleOpen={handleOpen}
                    />
                  </Step>
                </Stepper>
              </Paper>
            )}
          {created
            && (
              <Paper>
                <Box className="text-center pt-10 pb-10">
                  <Typography variant="h5">온데이가 등록되었습니다.</Typography>
                  <Typography variant="h6">아래 링크나 QR로 확인해 보세요.</Typography>
                  <br />
                  <br />
                  <Typography variant="body1">
                    <a href={`${host}/${id}`} target="_blank" rel="noreferrer">
                      {host}
                      /
                      {id}
                    </a>
                  </Typography>
                </Box>
                <Box className="max-w-[128px] mx-auto pt-10 pb-20">
                  <QRCode
                    size={256}
                    style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                    value={`${host}/${id}`}
                    viewBox="0 0 256 256"
                  />
                </Box>
              </Paper>
            )}

          <Box className="mt-4 mb-4">
            <a href="https://bien.ltd" target="_blank" rel="noreferrer">
              <img src={bienlogo} alt="logo" className="mx-auto h-10 " />
            </a>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Create;
