import logo from "../assets/images/logo.svg";

const Logo = () => {
  return(
    <div className={"absolute inset-x-0 bottom-0 h-16 z-30"}>
      <a href={"https://bien.ltd"} target={"_blank"}  rel="noreferrer" >
        <img src={logo}  alt="logo" className={"mx-auto h-10 "} />
      </a>
    </div>
  )
}

export default Logo