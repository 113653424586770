import './index.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';
import { customAlphabet } from 'nanoid';
import App from './App';

const getId = async () => {
  const nanoid = customAlphabet('1234567890abcdefghijklmopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 8);
  const id = await nanoid();
  console.log(`ID : ${id}`);
};
getId();

const theme = createTheme({
  palette: {
    primary: {
      main: '#6466e9',
    },
    secondary: {
      main: '#DDDDDD',
    },
    third: {
      main: '#FFFFFF',
    },
    background: {
      default: '#00FF00',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'Nanum Gothic',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
  },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

// var stats = new Stats();
// stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom
// document.body.appendChild( stats.dom );
// function animate() {
//   stats.begin();
//   // monitored code goes here
//   stats.end();
//   requestAnimationFrame( animate );
// }
// requestAnimationFrame( animate );
