import Logo from "./Logo";
import {Button} from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const PermissionFail = ()=>{
  const reload = ()=> {
    window.location.reload();
  }

  return(
    <div className={"bg-indigo-500 absolute inset-0 z-50"}>
      <div className={"flex justify-center items-center h-screen"}>
        <div className={"grid-rows-3 mb-40"}>
            <div className={"w-24 h-24 mx-auto mb-12"}>
              <WarningAmberIcon className={"text-yellow-400 !text-8xl"}  />
            </div>
          <div className={"text-white text-center text-lg mb-10"}>
            카메라 사용 권한이 없습니다.
            <br/>
            페이지를 새로고침 해주세요.
          </div>
          <div className={"d-grid"}>
            <Button fullWidth variant="contained" size="large" onClick={()=>reload()} >새로고침</Button>
          </div>
        </div>
      </div>
      <Logo/>
    </div>
  )
}

export default PermissionFail