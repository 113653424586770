import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  StepContent, Typography,
} from '@mui/material';
import ContentType from '../store/ContentType';
import useCreateStore from '../store/useCreateStore';

function CreateContentStep(props) {
  const { handleNext, handleBack } = props;
  const { content, setContentStore } = useCreateStore();

  const handleType = (e) => {
    setContentStore(e.target.value);
  };

  return (
    <StepContent>
      <Typography variant={'subtitle2'} >마커를 인식하여 보여질 콘텐츠를 선택해주세요.</Typography>

      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={content}
          name="radio-buttons-group"
          onChange={handleType}
        >
          <Grid container>

            <Grid item xs={12} md={4}>
              <Paper variant="outlined" className="min-h-full m-2">
                <img src="/assets/images/type_birthday.png" />
                <Box className="text-center">
                  <FormControlLabel value={ContentType.BIRTHDAY} control={<Radio />} label="생일" />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper variant="outlined" className="min-h-full m-2">
                <img src="/assets/images/type_wedding.png" />
                <Box className="text-center">
                  <FormControlLabel value={ContentType.WEDDING} control={<Radio />} label="웨딩" />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper variant="outlined" className="min-h-full m-2">
                <img src="/assets/images/type_couple.png" />
                <Box className="text-center">
                  <FormControlLabel value={ContentType.COUPLE} control={<Radio />} label="커플" />
                </Box>
              </Paper>
            </Grid>

          </Grid>
        </RadioGroup>
      </FormControl>

      <Divider variant="middle" className="!mt-4 !mb-4" />

      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{ mt: 1, mr: 1 }}
          >
            다음
          </Button>
          <Button
            onClick={handleBack}
            sx={{ mt: 1, mr: 1 }}
          >
            이전
          </Button>
        </div>
      </Box>
    </StepContent>
  );
}

export default CreateContentStep;
