import React, {useEffect, useState} from 'react';
import PermissionRequest from "./PermissionRequest";
import PermissionFail from "./PermissionFail";
import useDataStore from "../store/useDataStore";
import useStatusStore, {Status} from "../store/useStatusStore";

const OnDay = () => {
  const {data} = useDataStore(state => state)
  const {status} = useStatusStore(state => state)
  const [content, setContent] = useState(null)

  const createContnet = async ()=>{

    let content = null;
    if (data.content === "photocard") {
      content = await import('./OnDayPhotoCard');
    } else if (data.content === "photoday") {
      content = await import('./OnDayPhotoDay');
    } else if (data.content === "wedding") {
      content = await import('./OnDayWedding');
    } else if (data.content === "wishtree") {
      content = await import('./OnDayWishtree');
    } else if (data.content === "birthday") {
      content = await import('./OnDayBirthday');
    } else if (data.content === "couple") {
      content = await import('./OnDayCouple');
    } else if (data.content === "character") {
      content = await import('./OnDayCharacter');
    } else if (data.content === "character2") {
      content = await import('./OnDayCharacter2');
    } else if (data.content === "test") {
      content = await import('./OnDayTest');
    }

    if(content) setContent(<content.default/>)
  }

  useEffect( ()=>{
    if((status === Status.REQUEST || status === Status.READY)){
      createContnet();
    }
  }, [status])

  return (
    <div>
      {(status === Status.NONE || status === Status.REQUEST) &&
        <PermissionRequest/>}
      {(status === Status.FAIL && status !== Status.START) &&
        <PermissionFail/>}
      { content }
    </div>
  )
}

export default OnDay