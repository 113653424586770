import create from 'zustand'

export const Status = {
  NONE: "none",
  LOADING: "loading",
  REQUEST: "request",
  FAIL: "fail",
  READY: "ready",
  START: "start",
}

const useStatusStore = create(set => ({
  status: Status.NONE,
  setStatus: (s) => set({status: s}),
}))

export default useStatusStore