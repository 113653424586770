import React from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  StepContent, Typography
} from "@mui/material";
import ControllerType from "../store/ControllerType";
import useCreateStore from "../store/useCreateStore";

const CreateControllerStep = (props) => {
  const {handleNext, handleBack} = props;
  const {controller, setControllerStore} = useCreateStore();

  const handleContent = (e) => {
    setControllerStore(e.target.value);
  }

  return (
    <StepContent>
      <Typography variant={'subtitle2'} >이미지를 선택하시면 등록한 이미지를 인식하여 AR 보여집니다.<br/>
        얼굴 인식을 선택하시면 사람의 얼굴 인식하여 AR 컨텐츠가 보여집니다.</Typography>
      <FormControl className={"w-full"}>
        <RadioGroup
          aria-labelledby="content-radio-buttons-group-label"
          defaultValue={controller}
          name="content-radio-buttons-group"
          onChange={handleContent}
        >
          <Grid container >
            <Grid item xs={6}>
              <Paper variant="outlined" className="min-h-full m-2">
                <img src="/assets/images/controller_image.png" className={"mx-auto max-h-[30vh]"}/>
                <Box className="text-center">
                  <FormControlLabel value={ControllerType.IMAGE} control={<Radio/>} label="이미지 인식"/>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper variant="outlined" className="min-h-full m-2">
                <img src="/assets/images/controller_face.png" className={"mx-auto  max-h-[30vh]"}/>
                <Box className="text-center">
                  <FormControlLabel value={ControllerType.FACE} control={<Radio/>} label="얼굴 인식"/>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>

      <Divider variant="middle" className="!mt-4 !mb-4"/>

      <Box sx={{mb: 2}}>
        <div>
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{mt: 1, mr: 1}}
          >
            다음
          </Button>
        </div>
      </Box>

    </StepContent>
  );
};

export default CreateControllerStep;