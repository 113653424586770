import create from 'zustand';
import { customAlphabet } from 'nanoid';
import {
  doc, setDoc, serverTimestamp, Timestamp,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../FirebaseApp';
import ControllerType from './ControllerType';
import ContentType from './ContentType';

const getId = async () => {
  const nanoid = customAlphabet('1234567890abcdefghijklmopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 8);
  const id = await nanoid();
  console.log(`ID : ${id}`);
  return id;
};

const useCreateStore = create((set, get) => ({
  id: null,
  controller: ControllerType.IMAGE,
  setControllerStore: (o) => set({ controller: o }),
  image: null,
  setImageStore: (o) => set({ image: o }),
  marker: null,
  setMarkerStore: (o) => set({ marker: o }),
  content: ContentType.BIRTHDAY,
  setContentStore: (o) => set({ content: o }),
  title: null,
  setTitleStore: (o) => set({ title: o }),
  mobile: null,
  setMobileStore: (o) => set({ mobile: o }),
  onday: null,
  setOndayStore: (o) => set({ onday: o }),
  startday: false,
  setStartdayStore: (o) => set({ startday: o }),
  created: false,
  setCreated: (o) => set({ created: o }),

  fetch: async () => {
    console.log('%c Fetch start ...', 'color: cyan');

    const id = await getId();
    set({ id });
    const { controller } = get();
    const onday = new Timestamp(get().onday, 0);
    const now = serverTimestamp();

    let data = {
      id,
      controller,
      content: get().content,
      title: get().title,
      onday,
      startday: get().startday,
      create: now,
      update: now,
    };

    if (controller === ControllerType.IMAGE) {
      const thumbPath = `markers/${id}/target.png`;
      const thumbRef = ref(storage, thumbPath);
      const thumbSnap = await uploadBytes(thumbRef, get().image);
      const thumb = await getDownloadURL(thumbRef);
      console.log(thumbSnap);

      const targetPath = `markers/${id}/target.mind`;
      const targetRef = ref(storage, targetPath);
      const targetSnap = await uploadBytes(targetRef, get().marker);
      const target = await getDownloadURL(targetRef);
      console.log(targetSnap);

      data = { ...data, thumb, target };
    }

    console.log(`%c content: ${get().content}`, 'color: purple');
    console.log(`%c title: ${get().title}`, 'color: purple');
    console.log(`%c onday: ${get().onday}`, 'color: purple');
    console.log(`%c startday: ${get().startday}`, 'color: purple');

    const dataRef = doc(db, 'markers', id);
    const dataSnap = await setDoc(dataRef, data);
  },
}));

export default useCreateStore;
