import Logo from "./Logo";
import * as animationData from '../animations/loading.json'
import Lottie from "react-lottie";
import {useContext, useState} from "react";
import onday from "../assets/images/onday.svg";
import {Button} from "@mui/material";
import useStatusStore, {Status} from "../store/useStatusStore";
import '../index.css';

const PermissionRequest = (props) => {

  const {onSuccess, onFail} = props
  const [loading, setLoading] = useState(false)
  const {setStatus} = useStatusStore(state => state)

  const request = async () => {
    setLoading(true)
    setStatus(Status.REQUEST)
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={"absolute inset-0 z-50 bg-indigo-500  "} >
      <div className={"absolute w-full top-20"}>
        <div className={"w-fit mx-auto"}>
          <img src={onday} alt="onday" className={"mx-auto h-20 "}/>
        </div>
        <div className={"text-white text-center"}>기념일을 즐기는 새로운 방법</div>
      </div>
      <div className={"flex justify-center items-center h-full"}>
        <div className={"grid-rows-2 gap-10"}>
          <div className={"text-white text-center text-lg mb-10"}>
            AR 실행을 위하여
            <br/>
            카메라 사용을 허용해 주세요.
          </div>
          <div className={"d-grid"}>
            {loading ?
              <Lottie options={defaultOptions}
                      height={80}
                      width={80}/> :
              <Button fullWidth variant="contained" size="large" onClick={request} className={"custom_css"}>계속하기</Button>
            }
          </div>
        </div>
      </div>
      <Logo/>
    </div>
  )
}

export default PermissionRequest
